
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.layout-input-search {
  margin-bottom: math.div($gap, 2);
  background: $color-white;
  border-radius: $base-border-radius;
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 24px;
  box-shadow: $base-shadow-xl;

  @include for-size(phone-portrait-down) {
    padding: 5px 6px 5px 16px;
    .esmp-select-content {
      width: 0;
      overflow: hidden;
    }
    .esmp-select-head {
      padding-left: 0;
    }
    .esmp-select-postfix {
      margin-left: 0;
    }
  }

  &__icon {
    color: $color-black-op-50;
  }

  &__input {
    flex-grow: 1;
  }

  &__divider {
    height: $gap;
    width: 1px;
    background: $color-black-op-10;
    margin: 0 8px;
  }

  &__select {
    width: auto;
    max-width: 180px;
    color: $color-grayscale-80;
  }

  .esmp-input-element,
  .esmp-select-head {
    background: none;
  }
  .esmp-input-label {
    color: $color-black-op-75;
    font-weight: 500;
    @include for-size(phone-portrait-down) {
      font-size: 14px;
      padding: 0 0 0 6px;
    }
  }
}
